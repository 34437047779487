import { gql } from "graphql-request";

export const GetWidgetDetails = gql`
    query getWidgetDetails(
        $widget: String!,
        $ctaId: String,
        $variant: String
    ) {
        getWidgetDetails(
            widgetId: $widget,
            ctaId: $ctaId,
            variant: $variant
        ) {
            company {
                id
                name
                widgetId
                archived
                products
                analytics {
                    analyticsId
                    domain
                    type
                }
                country
            }
            group {
                id
                name
                legalDesc
                privacyPolicyUrl
                type
                distributionType
            }
            memberCompanies {
                id
                name
                widgetId
                archived
                products
                country
            }

            fields {
                product
                integration
                channel
                key
                value
            }

            productIntegrations {
                product
                integrations
            }
        }
    }
`;