import { h } from "preact";
import { useMemo, useEffect } from "preact/hooks";
import { useTranslation } from "react-i18next";
import useLocalStorageState from "@util/storage";

import "@style/global.scss";
import "@style/font-face.scss";
import "~/initial-setup";
import "~/i18n/config";

import { Provider, useStoreInstance } from "@store";
import { ga } from "@host";

import type {
    AppProps,
    TopLevelComponent,
    NextViewComponent,
} from "@model/view/internal";
import { frameHandler } from "@host/frame";

type NextAppProps = AppProps<TopLevelComponent<NextViewComponent, any>>;

function App(props: NextAppProps) {
    const {
        Component,
        pageProps = {},
    } = props;

    const store = useStoreInstance(pageProps.preloadedState);
    if (process.browser && RUNTIME_DEBUG) (window as any).store = store;

    // Set preferred language
    const { i18n } = useTranslation();
    useEffect(() => {
        const language = store.model.dealer.state.language;
        const lang = `${language}-${store.model.dealer.state.country}`;
        // Validate language code format (e.g., en-US, es-MX)
        const isValidLangCode = /^[a-z]{2}-[A-Z]{2}$/.test(lang);
        i18n.changeLanguage(isValidLangCode ? lang : "en-US");
    }, [i18n, store.model.dealer.state.language, store.model.dealer.state.country]);

    const RenderComponent = useMemo(() => {
        if (Component.Container) {
            return <Component.Container {...props} />;
        }

        return <Component {...pageProps} />;
    }, [Component, props, pageProps]);

    return (
        <Provider store={store}>
            {RenderComponent}
        </Provider>
    );
}

export default App;